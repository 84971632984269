// Core
import { graphql } from "gatsby";
import React from "react";
import get from "lodash/get";
import { StaticImage } from "gatsby-plugin-image";

// Components
import Layout from "../../layout/";
// Sections
import {
  RevenueSignUp,
  TextWithBanner,
  ThankYou,
} from "../../sections/";
const sections = {
  RevenueSignUp: RevenueSignUp,
  TextWithBanner: TextWithBanner,
  ThankYou: ThankYou,
};

class LandingPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulPageStandard");

    return (
      <Layout
        seo={{
          description: page_data.description ? page_data.description.description : undefined,
          path: page_data.path,
          title: page_data.title,
        }}
        noHeader={true}
      >
        <div className="px-6 py-5">
          <div className="cContainer cContainer--large">
            <StaticImage
              src="../../images/landing-logo.png"
              placeholder="blurred"
              alt="Logo"
              transformOptions={{ fit: "cover", cropFocus: "attention" }}
            />
          </div>
        </div>
        {page_data.sections.map((section, index) => {
          const Section = sections[section.internal.type.replace("ContentfulSection", "")];

          return (
            <Section
              data={section}
              key={index}
            />
          );
        })}
      </Layout>
    );
  }
}

export default LandingPageTemplate;

export const pageQuery = graphql`
  query LandingPageBypathQuery($path: String!) {
    contentfulPageStandard(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      sections {
        ... on Node {
          ... on ContentfulSectionRevenueSignUp {
            internal {
              type
            }
            id
            specialHeading {
              childMarkdownRemark {
                html
              }
            }
            body {
              raw
            }
            formTitle
            formDescription {
              raw
            }
            formName
            thankyouUrl
            images {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          ... on ContentfulSectionTextWithBanner {
            internal {
              type
            }
            id
            heading
            body {
              raw
            }
            image {
              title
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          ... on ContentfulSectionThankYou {
            internal {
              type
            }
            id
            heading
            body {
              raw
            }
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            downloadArticle
          }
        }
      }
    }
  }
`;
